















import Vue from 'vue'
import Component from 'vue-class-component'

import PhoneIcon from './icons/Phone.vue'
import HotlineIcon from './icons/Hotline.vue'
import MailIcon from './icons/Mail.vue'
import DesktopIcon from './icons/Desktop.vue'

@Component({
  // components: { PhoneIcon, DesktopIcon, MailIcon }
})
export default class Contact extends Vue {
  list = [
    { label: '089 26480759', value: '08926480759', link: 'tel:08926480759', component: HotlineIcon },
    { label: 'Beratungstermin vereinbaren', value: 'https://www.aok.de/pk/bayern/inhalt/beratungstermin-vereinbaren-1/', link: 'https://www.aok.de/pk/bayern/inhalt/beratungstermin-vereinbaren-1/', component: PhoneIcon },
    { label: 'Videoberatung vereinbaren', value: 'https://www.aok.de/pk/bayern/videoberatung-einfachgesund/', link: 'https://www.aok.de/pk/bayern/videoberatung-einfachgesund/', component: DesktopIcon },
    { label: 'einfachgesund@by.aok.de', value: 'einfachgesund@by.aok.de', link: 'mailto:einfachgesund@by.aok.de', component: MailIcon },
  ]
}
