













import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { CHATBOT_ROUTES } from './../.enums'

import Logo from './icons/Logo.vue'
import CloseIcon from './icons/Close.vue'
import InfoIcon from './icons/Info.vue'

@Component({
  components: { Logo, CloseIcon, InfoIcon }
})
export default class Welcome extends Vue {
  ROUTE = CHATBOT_ROUTES

  @Prop({ default: '' })
  title?: string

  toggleInfo() {
    const route = this.$store.state.route === this.ROUTE.RUNNING ? this.ROUTE.INFO : this.ROUTE.RUNNING
    this.$store.commit('setRoute', route)
  }

  handleCloseClick() {
    if(!this.$store.state.open && document.querySelector('.chatbot-window__open')) {
      document.querySelector('.chatbot-window__open').classList.remove('chatbot-window__open')
    }

    this.$store.commit('setOpenState', false)
  }
}
