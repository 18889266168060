














import Vue from 'vue'
import Component from 'vue-class-component';

import { CHATBOT_ROUTES } from './/.enums';

import Welcome from './components/Welcome.vue';
import ChatBot from './components/ChatBot.vue';
import Info from './components/Info.vue';

@Component({
  components: { Welcome, Info, ChatBot },
})
export default class App extends Vue {
  ROUTE = CHATBOT_ROUTES
}
