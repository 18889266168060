






import Vue from 'vue'
import Component from 'vue-class-component';

import ChatIcon from './components/icons/Chat.vue'

@Component({
  components: { ChatIcon }
})
export default class App extends Vue {
  get state() {
    return this.$store.state.open
  }
}
