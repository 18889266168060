







import Vue from 'vue'
import Component from 'vue-class-component'
import Header from './Header.vue'

import { BOTPRESS_EVENT_MSG } from './../.enums'

interface BOTPRESS_WEB_CONFIG {
  host: string
  botId: string
  hideWidget?: boolean
  enableReset?: boolean
  enableTranscriptDownload?: boolean
  enablePersistHistory?: boolean
  showConversationsButton?: boolean
  extraStylesheet?: string
}

@Component({
  components: { Header },
  watch: {
    open(newVal: boolean) {
      if(!newVal) return

      const _this: any = this

      _this.botpress.sendEvent({ type: 'show' })
    }
  }
})
export default class ChatBot extends Vue {
  config: BOTPRESS_WEB_CONFIG = {
    host: 'https://bot.einfachgesund.de',
    botId: 'service',
    hideWidget: true,
    enableReset: true,
    enableTranscriptDownload: false,
    showConversationsButton: false,
    enablePersistHistory: false,
    extraStylesheet: '/assets/modules/channel-web/style.css'
  }

  botpress: any = null
  botpressScript: string = `${this.config.host}/assets/modules/channel-web/inject.js`

  get open() {
    return this.$store.state.open
  }

  get wrapper(): HTMLElement {
    const ele: any = this.$refs.chatbot
    return ele
  }

  mounted() {
    this.createBotScript()
  }

  createBotScript() {
    if(this.$store.state.loaded) return

    const botScript = document.createElement('script')

    botScript.onload = this.onBotScriptLoad
    botScript.src = this.botpressScript

    this.wrapper.appendChild(botScript)
  }

  onBotScriptLoad() {
    this.$store.commit('setLoaded', true)
    const _window: any = window

    if(!this.botpress) {
      this.botpress = _window.botpressWebChat

      this.botpress.init(this.config, `#${this.wrapper.id}`)
    }

    _window.addEventListener('message', (event: any) => {
      if (event.data.name === BOTPRESS_EVENT_MSG.LOADED) {
        this.botpress.sendEvent({ type: 'show' })
      }
      if (event.data.name === BOTPRESS_EVENT_MSG.READY) {
        this.botpress.sendEvent({
          type: 'proactive-trigger',
          channel: 'web',
          payload: { text: 'fake message' }
        })
      }

      if(event.data.name === BOTPRESS_EVENT_MSG.CLOSED) {
        this.$store.commit('toggleOpenState')
      }
    })
  }
}
