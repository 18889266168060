import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    open: false,
    loaded: false,
    running: false,
    route: 'welcome',
  },
  mutations: {
    toggleOpenState(state) {
      state.open = !state.open
    },
    setOpenState(state, value: boolean) {
      state.open = value
    },
    setLoaded(state, loaded) {
      state.loaded = loaded
    },
    setRunning(state, running) {
      state.running = running
    },
    setRoute(state, route) {
      state.route = route
    }
  },
  actions: {
  },
  modules: {
  }
})
