



















































import Vue from "vue";
import Component from "vue-class-component";

import { CHATBOT_ROUTES } from "./../.enums";

import Header from "./Header.vue";
import Button from "./Button.vue";
import Contact from "./Contact.vue";

import Logo from "./icons/Logo.vue";

@Component({
  components: { Logo, Header, Button, Contact },
})
export default class Info extends Vue {
  ROUTE = CHATBOT_ROUTES

  navTitle: string = 'Service'
  fakeButtonTitle = 'Senden'
  chatArray = [
    {
      description: `
          <p>Hallo! Bevor es losgeht, kurz etwas zum Datenschutz.</p>
          <p>Bitte übermitteln Sie im Service-Chat keine Sozialdaten wie Diagnosen, Versichertennummer, persönliche Behandlungsdaten oder Angaben durch welche Rückschlüsse auf Ihre Person gezogen werden können.</p>
          <p>Informieren Sie sich außerdem vorab über unsere <a href="https://einfachgesund.de/de/chat-nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> und <a href="https://einfachgesund.de/de/datenschutz" target="_blank">Datenschutzerklärung</a>.</p>
          <p><strong>Mit dem Klick auf den folgenden Button akzeptieren Sie diese.</strong></p>
        `,
      btnTitle: "Akzeptieren und Assistent starten",
      btnClicked: false,
    },
  ]
  dotArray = [
    {
      transform: false
    },
    {
      transform: false
    },
    {
      transform: false
    }
  ]
  dotIndex = 0
  typingView = true

  get isChatRunning() {
    return this.$store.state.running
  }

  get LocalDateTime() {
    var now = new Date()
    const month = now.toLocaleString('default', { month: 'short' })
    return `${ now.getDate() }. ${ month }, ${ now.getHours() }:${ now.getMinutes() }`
  }

  get initState() {
    // if (this.$store.state.open) {
    //   setTimeout(this.init, 700)
    // }
    return this.$store.state.open
  }

  init() {
    for (let index = 1; index <= this.dotArray.length; ++index) {
      setTimeout(() => {
        this.dotArray[index - 1].transform = true
      }, (index * 70))
    }

    setTimeout(this.changeTypingView, 400)
  }

  mounted() {
    setTimeout(this.init, 700)
  }

  changeTypingView() {
    this.typingView = !this.typingView

    this.$nextTick()
  }

  async animation(index: number) {
    this.chatArray[ index ].btnClicked = true

    setTimeout(this.startChat, 1500)
  }

  startChat() {
    if (!this.isChatRunning) {
      this.$store.commit('setRunning', true)

      // matomo chat start event trigger
      const _paq: any = window['_paq'] || []
      _paq.push(['trackGoal', 3])
    }

    this.$store.commit('setRoute', this.ROUTE.RUNNING)
  }
}
