export enum CHATBOT_ROUTES {
  START = 'welcome',
  INFO = 'pause',
  RUNNING = 'in_chat'
}

export enum BOTPRESS_EVENT_MSG {
  LOADED = 'webchatLoaded',
  READY  = 'webchatReady',
  CLOSED = 'webchatClosed',
  OPENED = 'webchatOpened'
}
