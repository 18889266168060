

















import Vue from 'vue'
import Component from 'vue-class-component'

import { CHATBOT_ROUTES } from './../.enums'

import Header from './Header.vue'
import Button from './Button.vue'
import Contact from './Contact.vue'

import ChatBubbles from './icons/ChatBubbles.vue'

@Component({
  components: { ChatBubbles, Header, Button, Contact }
})
export default class Welcome extends Vue {
  ROUTE = CHATBOT_ROUTES

  navTitle: string = 'Service'
  title: string = 'Infos zum Datenschutz.'
  description: string = `
    <p>Bitte übermitteln Sie im Service-Chat keine Sozialdaten wie Diagnosen, Versichertennummer, persönliche Behandlungsdaten oder Angaben durch welche Rückschlüsse auf Ihre Person gezogen werden können.</p>
    <p>Informieren Sie sich außerdem  vorab über unsere <a href="https://einfachgesund.de/de/chat-nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> und <a href="https://einfachgesund.de/de/datenschutz" target="_blank">Datenschutzerklärung</a>.</p>
  `

  get isChatRunning() {
    return this.$store.state.running
  }

  get btnTitle(): string {
    return 'zurück zum Chat'
  }

  startChat() {
    if(!this.isChatRunning) {
      this.$store.commit('setRunning', true)

      // matomo chat start event trigger
      const _paq: any = window['_paq'] || []
      _paq.push(['trackGoal', 3])
    }

    this.$store.commit('setRoute', this.ROUTE.RUNNING)
  }
}
