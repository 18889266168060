import Vue from 'vue'
import Widget from './Widget.vue'
import Button from './Button.vue'
import store from './store'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(Button)
}).$mount('#chatbot-button')

new Vue({
  store,
  render: h => h(Widget)
}).$mount('#chatbot-widget')
